import { Affix, Button, Card, Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import BlankCard from "../components/BlankCard";
import CircleProgress from "../components/CircleProgress";
import { breakpoint } from "../theme";
import { Input, Select, } from "antd";
import InvoicesTable from "../components/InvoicesTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard, fetchInvoices, StatusList } from "../store/invoicesSlice";
import { RootState, useAppSelector } from "../store";
import { debounce } from "lodash";
import { fetchAccounts } from "../store/accountsSlice";
import { getStatusById, wrapWithBrackets } from "../utils";
const { Search } = Input;
const { Option } = Select;

interface Props {}

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

export interface Filter {
  statusFilter?: number;
  statusId?: number;
  vendorName?: string;
  fileName?: string;
  count: number;
  page: number;
  customerName?: string;
}

const SearchStyle = {
  margin: 5,
  display: "flex",
  flex: 1,
};

function Dashboard(props: Props) {
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const dashboard = useAppSelector((state) => state.invoices.dashboard);
  const { data: accounts, loading: loadingAccounts } = useAppSelector((state) => state.accounts);
  const [filter, setFilter] = useState<Filter>({
    statusId: +urlParams.get("statusId") || undefined,
    statusFilter: +urlParams.get("statusFilter") || undefined,
    fileName: urlParams.get("fileName") || undefined,
    vendorName: urlParams.get("vendorName") || undefined,
    page: +urlParams.get("page") || 1,
    count: +urlParams.get("count") || +localStorage.getItem("count") || 20,
    customerName: urlParams.get("customerName") || undefined,
  });

  const [historical, setHistorical] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchDashboard());
    dispatch(fetchAccounts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchInvoices({ filter, historical }));
  }, [filter, historical, dispatch]);

  const onFilterChange = (key: keyof Filter, value?: number | string) => {
    const newState = { [key]: value };
    //reset page if filtered by status filename or vendorname
    if (key !== "page") {
      newState.page = 1;
    }
    setFilter((state) => ({ ...state, ...newState }));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFilterChangeDebounced = useCallback(debounce(onFilterChange, 500), []);

  const onToggleChange = (checked) => setHistorical(checked);

  const filterByStatus = (statusId) => {
    onFilterChange("statusId", +statusId);
    onFilterChange("statusFilter", undefined);
  };
  return (
    <Layout>
      <div style={{ flex: 1, overflow: "hidden" }}>
        {!historical && (
          <BlankCard loading={!Object.keys(dashboard).length}>
            <Row>
              {
                StatusList.filter(s => s.showInFilter).map((status, index) => (
                  <CircleProgress
                    key={index}
                    onClick={() => filterByStatus(status.id)}
                    percent={dashboard[`${status.key}Percent`] * 100 || 0}
                    size={170}
                    title={dashboard[`${status.key}Count`]}
                    subtitle={status.label}
                    color={status.color}
                  />
                ))
              }
            </Row>
          </BlankCard>
        )}
        {/* <Affix offsetTop={-20}>  this is if we want to make this sticky */}
        <BlankCard>
          <div style={{ marginBottom: 15, paddingLeft: 5 }}>
            Historical: <Switch checkedChildren="On" unCheckedChildren="Off" onChange={onToggleChange} />
          </div>
          <Row>
            <Search
              style={SearchStyle}
              onChange={({ target }) => onFilterChangeDebounced("fileName", target.value)}
              defaultValue={urlParams.get("fileName")}
              allowClear
              placeholder="Email/File"
              size="large"
              loading={false}
            />

            <Select
              style={SearchStyle}
              loading={loadingAccounts}
              placeholder="Vendor"
              showSearch
              options={accounts.map((e) => ({
                key: e.Id,
                label: e.Name + wrapWithBrackets(e.Vendor_ID__c),
                title: e.Name + wrapWithBrackets(e.Vendor_ID__c),
                value: e.Id,
              }))}
              filterOption={(inputValue, option) => option.title.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              onSelect={(value) => onFilterChangeDebounced("vendorName", value.toString())}
              onClear={() => onFilterChangeDebounced("vendorName", undefined)}
              defaultValue={urlParams.get("vendorName")}
              size="large"
              allowClear
            ></Select>

            <Search
              style={SearchStyle}
              defaultValue={urlParams.get("customerName")}
              onChange={({ target }) => onFilterChangeDebounced("customerName", target.value)}
              allowClear
              placeholder="Customer"
              size="large"
              loading={false}
            />

            <Select
              allowClear
              value={getStatusById(filter.statusId)?.label}
              placeholder="Status"
              onChange={(value) => {
                onFilterChange("statusId", +value);
                onFilterChange("statusFilter", undefined);
              }}
              size="large"
              style={{ minWidth: 150, ...SearchStyle }}
            >
              {
                StatusList
                .filter(s => s.showInFilter)
                .map((status, index) => <Option key={index} value={status.id}>{status.label}</Option>)
              }
            </Select>
          </Row>
        </BlankCard>
        {/* </Affix> */}
        <BlankCard>
          <InvoicesTable onFilterChange={onFilterChange} />
        </BlankCard>
      </div>
    </Layout>
  );
}

export default Dashboard;
