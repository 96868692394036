import React from "react";
import { Table, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { RootState, useAppSelector } from "../store";
import { FinalizedData, Invoice, Status } from "../store/invoicesSlice";
import { useHistory } from "react-router";
import { explodeFileName, formatDate, getColorByStatusName, getDaysDiff } from "../utils";
import { Filter } from "../pages/Dashboard";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { UserOutlined } from "@ant-design/icons";
import { get } from "lodash";
import UserPresenceIcon from "./UserPresenceIcon";

interface Props {
  onFilterChange: (key: keyof Filter, value?: number | string) => void;
}

function InvoicesTable({ onFilterChange }: Props) {
  const urlParams = new URLSearchParams(window.location.search);
  const { data, loading, totalCount } = useSelector((state: RootState) => state.invoices);
  const { data: accounts } = useAppSelector((state) => state.accounts);
  const history = useHistory();
  const onRowClick = (record: Invoice, rowIndex?: number) => {
    history.push(`/invoice/${record.id}`);
  };

  const columns = [
    {
      title: "",
      dataIndex: "presenceUsers",
      render: (presenceUsers: string[]) => <UserPresenceIcon presenceUsers={presenceUsers} />,
    },
    {
      title: "Vendor",
      dataIndex: "finalizedData",
      responsive: ["xl"] as Breakpoint[],
      render: (finalizedData: FinalizedData, invoice: Invoice) =>
        accounts.find((account) => account.Id === finalizedData.vendor)?.Name ||
        get(invoice, "ocrData.analyzeResult.documentResults.0.fields.vendorName.text"),
    },
    {
      title: "Customer",
      dataIndex: "finalizedData",
      responsive: ["xl"] as Breakpoint[],
      render: (finalizedData: FinalizedData, invoice: Invoice) =>
        finalizedData.customer || get(invoice, "ocrData.analyzeResult.documentResults.0.fields.customerName.text"),
    },
    {
      title: "Email",
      dataIndex: "filename",
      render: (filename: string) => explodeFileName(filename).email,
    },
    {
      title: "File",
      dataIndex: "filename",
      render: (filename: string) => <span style={{ wordBreak: "break-word" }}>{explodeFileName(filename).filename}</span>,
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Received",
      dataIndex: "filename",
      render: (filename: string) => explodeFileName(filename).received_at,
      responsive: ["md"] as Breakpoint[],
    },
    {
      title: "Last Modified",
      dataIndex: "lastUpdatedAt",
      render: (date: string) => formatDate(date),
      responsive: ["xl"] as Breakpoint[],
    },
    {
      title: "Status",
      dataIndex: "finalizedData",
      responsive: ["md"] as Breakpoint[],
      render: (finalizedData: FinalizedData) => <span style={{ color: getColorByStatusName(finalizedData.status) }}>{finalizedData.status}</span>,
    },
    {
      title: "Elapsed Days",
      dataIndex: "filename",
      responsive: ["md"] as Breakpoint[],
      render: (filename: string) => getDaysDiff(explodeFileName(filename).received_at),
    },
  ];

  return (
    <Table
      onRow={(record, rowIndex) => ({
        onClick: (e) => onRowClick(record, rowIndex),
      })}
      loading={loading}
      rowKey={(e) => e.id}
      columns={columns}
      dataSource={data}
      pagination={{
        onChange: (page, pageSize) => {
          const prevPageSize = localStorage.getItem("count");
          localStorage.setItem("count", pageSize.toString());

          onFilterChange("page", page);

          if (prevPageSize !== pageSize.toString()) onFilterChange("count", pageSize);
        },

        total: totalCount,
        pageSize: +urlParams.get("count") || +localStorage.getItem("count") || 20,
        showSizeChanger: true,
        current: +urlParams.get("page") || 1,
        //pageSizeOptions: ["10", "20", "30", "50", "100"],
      }}
    />
  );
}
export default InvoicesTable;
