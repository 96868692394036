import React, { useEffect } from "react";
import styled from "styled-components";
import { BellOutlined, LogoutOutlined, LeftOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

import { Button } from "antd";

const color = "#393B40";

const StyledHeader = styled.header`
  height: 50px;
  font-size: 1.2em;
  background-color: white;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  color: ${color};
`;

function Header() {
  const history = useHistory();
  const location = useLocation();
  const { oktaAuth, authState } = useOktaAuth();
  const isInvoicePage = location.pathname.includes("invoice");
  return (
    <StyledHeader>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          //if navigated from dashboard history.action will be PUSH
          if (isInvoicePage && history.action !== "POP") {
            return history.goBack();
          }
          return history.push("/");
        }}
      >
        {isInvoicePage && <LeftOutlined />} TFS Invoice Review
        {/* <Button onClick={() => oktaAuth.signInWithRedirect()}>login</Button> */}
      </div>
      <div>
        <Button
          loading={authState.isPending}
          onClick={() => oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + "/login/callback" }).then(console.log)}
          type="primary"
          icon={<LogoutOutlined />}
          size="middle"
        >
          Logout
        </Button>
      </div>
    </StyledHeader>
  );
}

export default Header;
