import { useOktaAuth } from "@okta/okta-react";
import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import BlankCard from "../components/BlankCard";

interface Props {}

const CenteredDiv = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`;

const Title = styled.p`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #393b40;
`;

function LoginOkta({}: Props) {
  const { authState, oktaAuth } = useOktaAuth();
  return (
    <CenteredDiv>
      <BlankCard>
        <Title>TFS OCR</Title>
        <Button size="large" style={{ width: "100%", backgroundColor: "#007EC1", color: "white" }} onClick={() => oktaAuth.signInWithRedirect()}>
          Login With Okta
        </Button>
      </BlankCard>
    </CenteredDiv>
  );
}

export default LoginOkta;
