import React, { FC, useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Invoice from "./pages/Invoice";
import NotFound from "./pages/NotFound";
import Header from "./components/Header";
import { SecureRoute, Security, LoginCallback, useOktaAuth } from "@okta/okta-react";

import { useAppDispatch, useAppSelector } from "./store";

import useInterceptors from "./hooks/useInterceptors";
import Messages from "./components/Messages";

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import LoginOkta from "./pages/LoginOkta";

import { Space, Spin } from "antd";
import styled from "styled-components";
import useConfigLoader from "./hooks/useConfigLoader";
import { setUser } from "./store/userSlice";
import useSignalr from "./hooks/useSignalr";


const CenteredDiv = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Loading = () => (
  <CenteredDiv>
    <Spin size="large" />
  </CenteredDiv>
);

const App = ({envConfig, oktaAuth}: {envConfig: any, oktaAuth: any}) => {
  
  const isReady = useInterceptors(envConfig);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  useSignalr({ invoiceId: null, envConfig });
  useEffect(() => {
    if (!authState.isAuthenticated) {
      dispatch(setUser(null));
      return;
    }

    oktaAuth.getUser().then((userClaims) => {
      dispatch(setUser(userClaims));
    });
  }, [authState, dispatch, oktaAuth]); // Update if authState changes

  if (!authState.isAuthenticated && !authState.isPending) {
    return <LoginOkta />;
  }

  if (location.pathname === envConfig.oktaAuth.loginCallbackPath) {
    return (
      <>
        <Loading />
        <LoginCallback />
      </>
    );
  }
  if (authState.isPending || !isReady) {
    return <Loading />;
  }
  return (
    <>
      <Header />
      <Messages />
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route path="/invoice/:id" children={<Invoice envConfig={envConfig} />}></Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

const AppWithSecurity = () => {
  const {isConfigLoaded, envConfig, oktaAuth} = useConfigLoader();
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  return ( isConfigLoaded && 
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <App envConfig={envConfig} oktaAuth={oktaAuth} />
    </Security>
  );
};

const AppWithRouter = () => (
  <Router>
    <AppWithSecurity />
  </Router>
);

export default AppWithRouter;
