import React, { useEffect, useLayoutEffect, useState } from "react";

import axios from "axios";
import { useAppDispatch, useAppSelector } from "../store";
import { pushMessage } from "../store/messagesSlice";
import { useOktaAuth } from "@okta/okta-react";

function useInterceptors(envConfig) {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);

  const { authState } = useOktaAuth();
  useEffect(() => {
    if (!envConfig || !authState.isAuthenticated) return;

    setReady(true);
    const requestInterceptor = axios.interceptors.request.use(
      function (config) {
        //console.log("token", accessToken);
        config.baseURL = envConfig.appConfig.baseApiUrl;
        config.headers = {
          token: `${authState.accessToken.accessToken}`,
        };

        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    const responseInterceptor = axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (!error.response.status) return Promise.reject(error);
        const resData = error.response?.data;
        if (error.response.status === 400 && resData.includes("Duplicate values were found on the following invoices")) {
          return Promise.reject(resData);
        }
        dispatch(
          pushMessage({
            message: "Error " + error?.response?.status,
            description: error.message || "something went wrong",
            type: "error",
          })
        );
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.request.eject(responseInterceptor);
    };
  }, [dispatch, authState, envConfig]);
  return ready;
}

export default useInterceptors;
