import React from "react";
import { Progress } from "antd";
import styled from "styled-components";
import { colors } from "../theme";

interface Props {
  title?: string | number;
  subtitle?: string;
  size?: number;
  percent: number;
  onClick?: () => any;
  color?: string
}

const Title = styled.span`
  color: ${colors.secondary};
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  color: ${(props: { color: string }) => props.color};
`;

const SubTitle = styled.p`
  color: black;
  font-weight: bold;
  font-size: 0.8rem;
  text-align: center;
  color: ${(props: { color: string }) => props.color};
`;
const AbsoluteDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props: { size: number }) => props.size}px;
  height: ${(props: { size: number }) => props.size}px;
  textalign: center;
`;

function CircleProgress({ size = 200, percent, title, subtitle, onClick, color }: Props) {

  return (
    <div style={{ position: "relative", cursor: "pointer" }} onClick={() => onClick?.()}>
      <AbsoluteDiv size={size}>
        <div style={{ textAlign: "center" }}>
          <Title color={color}>{title}</Title>
          <SubTitle color={color}>{subtitle}</SubTitle>
        </div>
      </AbsoluteDiv>
      <Progress
        width={size}
        strokeWidth={10}
        strokeColor={color}
        trailColor="#EBECF0"
        type="circle"
        percent={percent}
        format={(percent) => ``}
      />
    </div>
  );
}

export default CircleProgress;
