import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import invoices from "./invoicesSlice";
import messages from "./messagesSlice";
import accounts from "./accountsSlice";
import assets from "./assetsSlice";
import customers from "./customersSlice";
import user from "./userSlice";
const reducer = combineReducers({ accounts, user, invoices, messages, assets, customers });
export type RootState = ReturnType<typeof reducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
  //devTools: process.env.NODE_ENV === "development",
});

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
