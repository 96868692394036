import { useOktaAuth } from "@okta/okta-react";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import BlankCard from "../components/BlankCard";
import InvoiceFields from "../components/InvoiceFields";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchAccounts } from "../store/accountsSlice";
import { fetchAssets } from "../store/assetsSlice";
import { fetchCustomers } from "../store/customersSlice";
import { breakpoint } from "../theme";
import { explodeFileName, formatDate } from "../utils";
import useSignalr from "../hooks/useSignalr";
import { fetchInvoice, fetchSalesForceFields, setInvoiceData, updateInvoice, uploadFiles } from "../store/invoicesSlice";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import UserPresenceIcon from "../components/UserPresenceIcon";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const { confirm } = Modal;

interface Props {
  envConfig: any
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  //height: calc(100vh - 100px);

  overflow: hidden;
  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;
const BoldText = styled.span`
  font-weight: bold;
`;

function Invoice({ envConfig }: Props) {
  const { id } = useParams<{ id: string }>();
  const invoice = useAppSelector((state) => state.invoices.current);
  const loading = useAppSelector((state) => state.invoices.loading);

  useSignalr({ invoiceId: id, envConfig });

  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchCustomers());
    dispatch(fetchAssets());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchInvoice(id));
    dispatch(fetchSalesForceFields());
  }, [id, dispatch]);

  const pdfUrl =
    encodeURI(invoice.fileURL) +
    "?sv=2020-02-10&ss=b&srt=sco&sp=rl&se=2050-12-31T01:26:13Z&st=2021-03-30T16:26:13Z&spr=https&sig=GQ43LUOaPN5HCSSebTUVCJ%2FCGgydLlBdEmsZKNiGsBU%3D";
  const errorMessage = invoice.ocrData?.error?.message;
  const isDuplicate = invoice?.finalizedData?.isDuplicate;
  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    setUploading(true);

    return dispatch(uploadFiles({ id, formData }))
      .then((res) => {
        setFileList([]);
        setUploading(false);
      })
      .catch((e) => setUploading(false));
  };

  const disableUpload = invoice.finalizedData?.status === "Approved" 
                        || invoice.finalizedData?.status === "Rejected" 
                        || invoice.finalizedData?.status === "Sent to Salesforce";

  const propsUpload = {
    name: "file",
    multiple: true,
    disabled: disableUpload,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const getFileName = (url) => url.split("/").pop();

  const getConfirmContent = (message) => {
    const messageParts = message.split(": ")
    const urls = messageParts[1].split(",");
    return (
      <div>
        <p>{messageParts[0]}</p>
        <ul>
        {urls.map((url, index) => (
          <li key={index}>
            <a key={index} target="_blank" style={{textDecoration: "underline", fontWeight: "bold"}} href={`${window.location.origin}/invoice/${url}`}>{url}</a>
          </li>
        ))}
        </ul>
      </div>
    );
  }

  function showConfirm(data, message) {
    confirm({
      title: 'Are you sure you want to submit?',
      okText: 'Yes',
      icon: <ExclamationCircleOutlined />,
      content: getConfirmContent(message),
      onOk() {
        dispatch(updateInvoice({ id: invoice.id, finalizedData: data, ignore: true }));
        setIsModalVisible(false);
      },
      onCancel() {
        dispatch(setInvoiceData(data));
        setIsModalVisible(false);
      },
    });
  }

  return (
    <Row>
      <div style={{ height: "calc(100vh - 50px)", flex: 1 }}>
        {invoice.fileURL && <embed src={pdfUrl} key={pdfUrl} style={{ height: "100%", width: "100%", flex: 1 }} />}
      </div>
      <div style={{ height: "calc(100vh - 50px)", overflowX: "hidden", flex: 1 }}>
        <BlankCard loading={loading}>
          <p>
            <span>Email</span>: <BoldText>{explodeFileName(invoice.filename).email}</BoldText>
          </p>
          <p>
            <span>File</span>: <BoldText>{explodeFileName(invoice.filename).filename}</BoldText>
          </p>
          <p>
            <span>Received</span>: <BoldText>{explodeFileName(invoice.filename).received_at}</BoldText>
          </p>
          <p>
            <span>Last Modified</span>: <BoldText>{formatDate(invoice.lastUpdatedAt)}</BoldText>
          </p>
          <p>
            <span>Status</span>: <BoldText>{invoice.finalizedData?.previousStatus ? invoice.finalizedData?.previousStatus : invoice.finalizedData?.status}</BoldText>
          </p>
          <p>
            <span>OCR</span>: <BoldText>{invoice.status}</BoldText>
          </p>

          {errorMessage && <p>{errorMessage}</p>}
          {isDuplicate && (
            <div>
              <p style={{ color: "red" }}>Potential duplicate identified due to invoice number and invoice date matching another invoice.</p>
              <span>Potential Duplicate: </span>
              <ul>
                {invoice?.finalizedData?.duplicateIds.map((id, index) => (
                  <li key={index}>
                    <a key={index} target="_blank" style={{textDecoration: "underline", fontWeight: "bold"}} href={`${window.location.origin}/invoice/${id}`}>{id}</a>
                  </li>
                ))}

              </ul>
               
            </div>
          )}
          <UserPresenceIcon presenceUsers={invoice.presenceUsers} />
        </BlankCard>
        <BlankCard loading={loading} style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
          <InvoiceFields onDuplicateError={(data, message) => showConfirm(data, message)}/>
          <div>
            <h2>Additional Documents</h2>
            {invoice && invoice.finalizedData && (
              <ul>
                {invoice.finalizedData.documents.map((doc, index) => {
                  return (
                    <li key={index}>
                      <a target="_blank" href={doc}>
                        {getFileName(doc)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
            <Dragger {...propsUpload} accept=".doc,.docx,.pdf">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Only .pdf, .docx and .doc supported</p>
            </Dragger>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0 || disableUpload}
              loading={uploading}
              size="large"
              style={{ width: 100, alignSelf: "center", margin: 10 }}
            >
              {uploading ? "Uploading" : "Upload"}
            </Button>
          </div>
        </BlankCard>
      </div>
    </Row>
  );
}

export default Invoice;
