import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input, Select, Button, DatePicker, AutoComplete, Cascader, Form, Modal, Popconfirm } from "antd";
import styled from "styled-components";
import { breakpoint, colors } from "../theme";
import { approveInvoice, SalesForceField, updateInvoice } from "../store/invoicesSlice";
import { useAppDispatch, useAppSelector } from "../store";
import moment from "moment";
import { addMessage, pushMessage } from "../store/messagesSlice";
import { CustomerRecord } from "../store/customersSlice";
import { AssetRecord } from "../store/assetsSlice";
import { wrapWithBrackets } from "../utils";
import { EditOutlined } from "@ant-design/icons";
const TextArea = Input.TextArea;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:nth-child(odd) {
    background-color: #fafbfc;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const RowItem = styled.div`
  flex: 1;
  align-self: center;
  margin: 10px;
`;

const StyledContainer = styled.div`
  margin-top: 20px;
  @media only screen and ${breakpoint.device.sm} {
    //height: calc(100vh - 480px);
  }
  flex: 1;
  flex-direction: column;
`;
const parseMomentDate = (dateStr: any) => {
  if (!dateStr) return undefined;
  try {
    const parsed = new Date(dateStr);

    const momentDate = moment(parsed.toISOString().slice(0, 10));

    return momentDate;
  } catch (e) {
    console.log("cannot parse");
  }
  return undefined;
};

interface Props {
  onDuplicateError: (data, message) => void;
}

function InvoiceFields({ onDuplicateError }: Props) {
  const [filter, setFilter] = useState("");
  const dispatch = useAppDispatch();
  const fields = useAppSelector((state) => state.invoices.salesForceFields);

  const { data: accounts, loading: accountsLoading } = useAppSelector((state) => state.accounts);
  const { data: customers, loading: customersLoading } = useAppSelector((state) => state.customers);
  const { data: assets, loading: assetsLoading } = useAppSelector((state) => state.assets);
  const invoice = useAppSelector((state) => state.invoices.current);
  const [data, setData] = useState(fields);
  const [showErrors, setShowErrors] = useState(false);
  const invalidFields = useRef(new Set());
  const [selectedAsset, setSelectedAsset] = useState<string>(null);
  const [submitAllowed, setSubmitAllowed] = useState(true);

  const getSelectedAsset = useMemo<AssetRecord>(() => assets.find((e) => e.Id === selectedAsset), [selectedAsset, assets]);

  const getSelectedCustomer = useMemo<CustomerRecord>(() => {
    if (!selectedAsset) return;
    const selectedAccountId = assets.find((e) => e.Id === selectedAsset)?.AccountId;
    if (selectedAccountId) {
      return customers.find((e) => e.Id === selectedAccountId);
    }
  }, [selectedAsset, assets, customers]);

  /* const dataObject = useRef({});

  useEffect(() => {
    //saving in object
    const obj = data.reduce((obj, curr) => {
      obj[curr.id] = curr.value;
      return obj;
    }, {});
    dataObject.current = obj;
  }, [data]); */

  const getValueFromDataArray = (key) => {
    return data.find((el) => el.id === key)?.value;
  };

  const getValueFromFinalizedData = useCallback(
    (key: string, type?: string) => {
      if (!invoice || !invoice.finalizedData) return null;

      const val = invoice.finalizedData[key];
      if (type === "date" && val) {
        try {
          const dateVal = new Date(val).toISOString().slice(0, 10);
          return dateVal;
        } catch (e) {
          console.log(e);
        }
      }
      return val;
    },
    [invoice]
  );

  const onSave = () => {
    //dispatch(updateInvoice({ id: invoice.id, finalizedData: FINALIZED_DATA }))

    const finalizedData = {};

    data.map((e) => (finalizedData[e.id] = e?.value || ""));
    finalizedData["assetLocation"] = getSelectedCustomer?.Id;

    if (finalizedData["status"] === "Approved" || finalizedData["status"] === "Rejected") {
      setShowErrors(true);

      if (invalidFields.current.size)
        return dispatch(
          pushMessage({
            message: "Form Is Not Valid",
            description: "Missing: " + Array.from(invalidFields.current).toString(),
            type: "error",
          })
        );
    }

    //@ts-ignore
    dispatch(updateInvoice({ id: invoice.id, finalizedData, ignore: false })).then((res) => {
      console.log(res);
      if (res.meta.requestStatus === "rejected") {
        onDuplicateError(finalizedData, res["error"].message);
      }
    });
  };

  const checkIfSubmit = useCallback(() => {
    if (!invoice || !invoice.finalizedData) return null;
    return (
      ["Needs Review", "Corrections Needed"].includes(invoice.finalizedData?.status) ||
      ["Needs Review", "Corrections Needed"].includes(invoice.finalizedData?.previousStatus)
    );
  }, [invoice]);

  useEffect(() => {
    setData(fields.map((el) => ({ ...el, value: getValueFromFinalizedData(el.id, el.type) || el.default })));
    setSubmitAllowed(checkIfSubmit());
  }, [fields, getValueFromFinalizedData, checkIfSubmit]);

  const handleChange = (key: string, value: string | boolean) => {
    const tempData = [...data];
    let index = tempData.findIndex((el) => el.id === key);
    tempData[index] = { ...tempData[index], value } as SalesForceField;

    //Set value in lookup fields, labels are filtered out since value is displayed in renderField function
    tempData
      .filter((f) => f.type !== "label" && f.relatedField === key)
      .forEach((field) => {
        if (field.relatedField === key) {
          if (field.lookupField && key === "asset") {
            index = tempData.findIndex((el) => el.id === field.id);
            const selected = assets.find((e) => e.Id === value);
            console.log(selected);
            tempData[index] = { ...tempData[index], value: selected[field.lookupField] };
          }
        }
      });

    setData([...tempData] as SalesForceField[]);
  };

  const getValidatedStatus = (el: SalesForceField) => {
    const assetLocationValidation = el.id === "assetLocation" ? getSelectedCustomer?.Id : null;

    //check if field is required ant not valid
    const isInvalid = !el?.value && !el.default && !assetLocationValidation;

    if (isInvalid && checkIfRequired(el)) {
      invalidFields.current.add(el.name);

      return showErrors ? "error" : "success";
    }
    invalidFields.current.delete(el.name);

    return "success";
  };

  const checkIfRelatedFieldEqual = (el: SalesForceField) => getValueFromDataArray(el.enableIfField) === el.enableIfValue;

  const checkIfRequired = (el: SalesForceField): boolean => {
    if (el.enableIfField && el.enableIfRequired) {
      if (checkIfRelatedFieldEqual(el)) return true;

      return false;
    }
    if (el.required) {
      return true;
    }

    return false;
  };

  const renderLabel = (el: SalesForceField) => {
    const isRequired = checkIfRequired(el);

    //if (el.type === "label") return null;
    return isRequired ? `${el.name} *` : el.name;
  };

  const disabledDate = (current) => {
    // Can not select future days
    return current && current > moment().endOf("day");
  };

  const renderField = (el: SalesForceField, disabled: boolean) => {
    let finalizedDataValue = getValueFromFinalizedData(el.id);

    if (el.lookupURL?.endsWith("accounts")) {
      const ocrVendorName = el.value || finalizedDataValue;
      const vendorId = ocrVendorName
        ? accounts.find(
            (account) => account.Name.toUpperCase() === ocrVendorName.toUpperCase() || account.Id.toUpperCase() === ocrVendorName.toUpperCase()
          )?.Id
        : undefined;

      if (vendorId !== el.value) {
        handleChange(el.id, vendorId);
      }

      return (
        <>
          {!vendorId && <p style={{ fontSize: 12, position: "absolute", top: 0 }}>{ocrVendorName} (Not Found)</p>}
          <Select
            loading={accountsLoading}
            showSearch
            options={accounts.map((e) => ({
              key: e.Id,
              label: e.Name + wrapWithBrackets(e.Vendor_ID__c),
              title: e.Name + wrapWithBrackets(e.Vendor_ID__c),
              value: e.Id,
            }))}
            style={{ width: "100%" }}
            filterOption={(inputValue, option) => option.title.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            onSelect={(value) => handleChange(el.id, value)}
            value={vendorId}
            disabled={disabled}
          ></Select>
        </>
      );
    }

    if (el.lookupURL?.endsWith("assets")) {
      if (el.value && !selectedAsset) setSelectedAsset(el.value);
      //selectedAsset.current = el.value;
      return (
        <Select
          loading={assetsLoading}
          showSearch
          options={assets.map((e) => ({
            key: e.Id,
            label: e.Name + ` (${e.SerialNumber})`,
            title: e.Name + ` (${e.SerialNumber})`,
            value: e.Id,
          }))}
          style={{ width: "100%" }}
          filterOption={(inputValue, option) => option.title.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          onSelect={(value) => {
            handleChange(el.id, value);
            setSelectedAsset(value);
          }}
          value={el.value}
          disabled={disabled}
        ></Select>
      );
    }
    if (el.lookupURL?.endsWith("customers")) {
      return (
        <Select
          loading={customersLoading}
          options={
            getSelectedCustomer
              ? [
                  {
                    key: getSelectedCustomer?.Id,
                    label: getSelectedCustomer?.Name,
                    title: getSelectedCustomer?.Name,
                    value: getSelectedCustomer?.Id,
                  },
                ]
              : []
          }
          style={{ width: "100%" }}
          value={getSelectedCustomer?.Id}
          disabled={disabled}
        ></Select>
      );
    }

    if (el.type === "date") {
      return (
        <DatePicker
          format="MM/DD/YYYY"
          style={{ width: "100%" }}
          onChange={(_, value) => handleChange(el.id, value)}
          value={parseMomentDate(el.value)}
          disabled={disabled}
          disabledDate={disabledDate}
        />
      );
    }

    if (el.type === "dropdown") {
      let options = Array.isArray(el.dropdownOptions) ? el.dropdownOptions : [];

      if (el.dropdownCascade) {
        //getting selected cascade from dataArray or finalized data
        const selectedCascade = getValueFromDataArray(el.dropdownCascade) || getValueFromFinalizedData(el.dropdownCascade);

        if (!selectedCascade) {
          options = [];
        } else {
          options = el.dropdownOptions[selectedCascade] || [];

          if (el.value && !options.includes(el.value)) {
            handleChange(el.id, "");
          } else if (options.length === 1 && !el.value) {
            handleChange(el.id, options[0]);
          }
        }
      }

      if (el.name === "Status" && finalizedDataValue === "Corrections Needed") {
        options = ["Needs Review"];
      }

      return (
        <Select
          showSearch
          options={options.map((e: string) => ({ key: e, label: e, value: e }))}
          style={{ width: "100%" }}
          onSelect={(value) => handleChange(el.id, value)}
          value={el.value}
          disabled={disabled}
        ></Select>
      );
    }

    if (el.type === "textarea") {
      return <TextArea disabled={disabled} autoSize={{ minRows: 2 }} onChange={(e) => handleChange(el.id, e.target.value)} value={el.value} />;
    }

    if (el.type === "label") {
      if (el.relatedField === "asset" && el.lookupField && getSelectedAsset) {
        return getSelectedAsset[el.lookupField];
      }
      return null;
    }

    return (
      <Input disabled={disabled} readOnly={!!el.readOnly} onChange={(e) => handleChange(el.id, e.target.value)} type={el.type} value={el.value} />
    );
  };

  const renderFormItem = (el: SalesForceField) => {
    if (el.enableIfField && !checkIfRequired(el)) {
      //remove from invalid fields if this field is not visible anymore
      invalidFields.current.delete(el.name);
      if (!checkIfRelatedFieldEqual(el)) {
        //clear hidden field value to resolve staled data cacscade bug (when selecting Other option)
        if (el.value) return handleChange(el.id, "");
        return null;
      }
      // return null;
    }
    return (
      <Form.Item
        style={{ width: "100%", display: "inline" }}
        required={checkIfRequired(el)}
        // labelCol={{ span: 10 }}
        // wrapperCol={{ span: 14 }}
        key={el.id}
        //label={el.name}
        validateStatus={getValidatedStatus(el)}
      >
        <Row style={{ marginTop: el.type === "label" ? 0 : 20 }}>
          <span style={{ flex: 1 }}>{renderLabel(el)}</span>
          <span style={{ flex: 1 }}>{renderField(el, !submitAllowed)}</span>
        </Row>
      </Form.Item>
    );
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Input.Search onChange={(e) => setFilter(e.target.value)} allowClear placeholder="Search Field" size="large" loading={false} />

      <StyledContainer>
        {data
          .filter(
            (el) =>
              el.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
              el.ocrField?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
          )
          .map((el, index) => renderFormItem(el))}

        {submitAllowed && (
          <Button size="large" style={{ width: 100, alignSelf: "center", margin: 10 }} onClick={onSave}>
            Submit
          </Button>
        )}
        {invoice.finalizedData?.status === "Approved" && !submitAllowed && (
          <Popconfirm title="Are you sure you want to edit this invoice?" okText="Yes" cancelText="No" onConfirm={() => setSubmitAllowed(true)}>
            <Button style={{ margin: 10 }} size="large">
              <EditOutlined />
              Edit
            </Button>
          </Popconfirm>
        )}
      </StyledContainer>
    </div>
  );
}

export default InvoiceFields;
