import { UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

interface Props {
  presenceUsers: string[];
}

function UserPresenceIcon({ presenceUsers }: Props) {
  return !!presenceUsers?.length ? (
    <Tooltip placement="topLeft" title={presenceUsers.toString()}>
      <UserOutlined />
    </Tooltip>
  ) : null;
}

export default UserPresenceIcon;
