import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserClaims } from "@okta/okta-auth-js";
import axios from "axios";

const initialUserState = {
  loading: false,
  userClaims: {} as UserClaims,
};


const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser(state, action: PayloadAction<UserClaims>) {
      state.userClaims = action.payload;
    },
  },
  extraReducers: {
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
