import React, { useState, useEffect } from "react";
import axios from "axios";
import { OktaAuth } from "@okta/okta-auth-js";

function useConfigLoader() {
  const [config, setConfig] = useState(null);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [oktaAuth, setOktaAuth] = useState(null);

  useEffect(() => {
    if (isConfigLoaded) return;
    const data = {
      oktaAuth: {
        issuer: "https://sso.onpointgroup.com",
        clientId: "0oaakslfvxCM3a3SC2p7",
        loginCallbackPath: "/login/callback",
      },
      appConfig: {
        baseApiUrl: "https://tfsocrfunction-dev.azurewebsites.net/api",
        signalUrl: "https://tfsocrsignalrserver-dev.azurewebsites.net/api/presence",
        name: "dev",
      },
    };
    // axios
    //   .get(window.location.origin + "/env.json")
    //   .then(function (response) {
    const oktaAuth = new OktaAuth({
      issuer: data.oktaAuth.issuer,
      clientId: data.oktaAuth.clientId,
      redirectUri: window.location.origin + data.oktaAuth.loginCallbackPath,
    });
    setOktaAuth(oktaAuth);
    setConfig(data);
    setIsConfigLoaded(true);
    // })
    // .catch(function (error) {
    //   console.log("Could not load application configuration.");
    //   console.log(error);
    // });
  }, [isConfigLoaded]);

  return { isConfigLoaded: isConfigLoaded, envConfig: config, oktaAuth: oktaAuth };
}

export default useConfigLoader;
