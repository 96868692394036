import React, { useEffect, useLayoutEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useAppDispatch, useAppSelector } from "../store";
import { addOrRemovePresence } from "../store/invoicesSlice";
import { useRef } from "react";

function useSignalr({ invoiceId, envConfig }: { invoiceId: string, envConfig: any }) {
  const userName = useAppSelector((state) => state.user?.userClaims?.email);
  const [isConnected, setIsConnected] = useState(false);
  const dispatch = useAppDispatch();
  const connection = useRef<HubConnection>(null);

  const AddUserToPresence = (userName: string, invoiceId: string) => {
    connection.current.invoke("AddUserToPresence", userName, invoiceId).then();
  };
  
  const RemoveUserFromPresence = (userName: string, invoiceId: string) => {
    connection.current.invoke("RemoveUserFromPresence", userName, invoiceId).then();
  
  };
  useEffect(() => {
    connection.current = new HubConnectionBuilder().withUrl(envConfig.appConfig.signalUrl).configureLogging(LogLevel.Error).withAutomaticReconnect().build();
  }, []);

  useEffect(() => {
    if (!connection.current) return;
    if (connection.current.state === "Disconnected") {
      connection.current.start().then((_) => {
        connection.current.on("newMessage", (res) => {
          //res connected[:]Ahawkins@focustapps.com[:]jskees@mhequipment.com_2021-06-23T14:20:51 00:00_CDBK55.pdf
          const [status, user, id] = res.split("[:]");
          dispatch(addOrRemovePresence({ status, user, id }));
        });
        setIsConnected(true);
      });
    }
    if (connection.current.state === "Connected") setIsConnected(true);
  }, [dispatch]);

  useEffect(() => {
    if (!userName || !invoiceId || !isConnected) return;

    AddUserToPresence(userName, invoiceId);

    const id = setInterval(() => {
      AddUserToPresence(userName, invoiceId);
    }, 30000);

    return () => {
      clearInterval(id);
      RemoveUserFromPresence(userName, invoiceId);
    };
  }, [isConnected, userName, invoiceId]);
}

export default useSignalr;
