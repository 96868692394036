import { StatusList, StatusObj } from "../store/invoicesSlice";
import moment from "moment";

export function ObjectToQueryParam(params: Object): string {
  return (
    Object.keys(params)
      //@ts-ignore
      .filter((key) => params[key])
      //@ts-ignore
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join("&")
  );
}

export function formatDate(dateString: string) {
  const offset = moment().utcOffset();
  return moment.utc(dateString?.slice(0, 19)).utcOffset(offset).format("YYYY-MM-DD h:mm:ss a");
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return [s4(), s4(), "-", s4(), "-", s4(), "-", s4(), "-", , s4(), s4(), s4()].join("").toLowerCase();
}

export const getColorByStatusName = (statusName: string): string => {
  const status = StatusList.find(s => s.label === statusName);
  if(!status || !status.color)
    return "#000";

  return status.color;
};

export const getStatusById = (id: number): StatusObj => {
  return StatusList.find(s => s.id === id);
}

export const getStatusByLabel = (label: string): StatusObj => {
  return StatusList.find(s => s.label.toLowerCase() === label.toLowerCase());
}

export const explodeFileName = (str: string) => {
  const res = str?.split("[:]");
  return {
    email: res ? res[0] : "",
    received_at: res ? formatDate(res[1]) : "",
    filename: res?.slice(2).join("") || "",
  };
};

export const saveJson = (key: string, json: any) => {
  return localStorage.setItem(key, JSON.stringify(json));
};

export const getJson = (key: string) => {
  return JSON.parse(localStorage.getItem(key)) || [];
};

export const getDaysDiff = (date: string) => {
  return moment(new Date(), "YYYY-MM-DD").diff(moment(date).format("YYYY-MM-DD"), "days");
};

export const wrapWithBrackets = (str: string) => (str ? ` (${str})` : "");
